.dashContainer {  
  
  width: 100vw;
  height: 100vh;
  display: flex;
}

  .sideBarMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    width: 20vw;
    height: 100vh;
    background-color: #d9d9d9;
    border-radius: 17px;
    padding: 15px 15px 150px 15px;
    margin-bottom: 10px;
  }
  .sideBarMenu .btnMenu {
    height: 80px;
    margin: 20px 0;
    box-shadow: 1px 1px 4px #7b7a7a;
  }
  .contentPage {
    border: 10px solid #0d6efd;
    padding-top: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 10px;
    border: 2px solid #dfdfdf;
    box-shadow: 2px 2px 5px #7b7a7a;
    border-radius: 17px;
    background-color: #fff;
    width: 80vw;
    height: 85vh;
  }
  
  .footer {
    background-color: rgb(0, 0, 0);
    color: white;
    position: fixed;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    width: 83vw;
    bottom: 0;
  }
  .footer .btnFooter {
    margin-right: 10px;
    height: 45px;
    margin-left: 40%;
  }
  
  .footer .progBar {
    margin-top: 17px;
    justify-content: center;
    align-items: center;
    width: 200px;
    text-align: center;
  }
  .footer .progBar p {
    font-size: 14px;
  }