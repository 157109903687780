.carouselContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    border: 1px solid #dfdfdf;
    border-radius: 17px;
    background-color: #fff;
    gap: 20px;
    padding: 20px 20px 20px 20px;
    width: 400px;
    overflow-y: auto;
}
.imgCarousel {
    width: auto;
    max-height: 450px;
}


@media screen and (max-width: 450px){
    .carouselContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        text-align: left;
        border: 1px solid #dfdfdf;
        border-radius: 17px;
        background-color: #fff;
        gap: 20px;
        padding: 20px 20px 20px 20px;
        width: 100%;
        overflow-y: auto;
    }
    .imgCarousel {
        width: 100%;
        
    }

}