.internoCriarLinks {
  
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    border-radius: 17px;
    background-color: #fff;
    width: 100%;
    
    
}

.btnCriarLinkContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.btnPrint {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0d6efd;
    color: white;
    width: 65px;
    height: 38px;
    border-radius: 10%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.btnPrint:hover {
    background-color: #0b5ed7;
}