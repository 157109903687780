.box {
    border: 1px solid #dfdfdf;
    background-color: white;
    border-radius: 17px;
    width: 400px;
  }
.bnt {
  box-shadow: 2px 2px 5px #7b7a7a;
}
.link {
  margin-top: 10px;
  text-decoration: none;
  color: black;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}
.link:hover {
  cursor: pointer;
  color: #0d6efd;
}