.internoLisarLinks {
  
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 17px;
    background-color: #fff;
    margin: 15px;
    margin-bottom: 20px;
    width: 100%;
    
    
}

.btnList {
    color: black;
    font-size: 12px;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
.btnList:hover {
    color: red;
}

.qrPrintScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btnPrint {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0d6efd;
    color: white;
    width: 65px;
    height: 38px;
    border-radius: 10%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.btnPrint:hover {
    background-color: #0b5ed7;
}

.previousBttn {
    left: 0;
    color: rgb(255, 255, 255);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
    margin: 0px 2px 0px 2px;
    width: 40px;
    height: 30px;
    border-radius: 20%;
    background-color: #106cfc;
    border: 1px solid #106cfc;
    transition: all 0.3s;
}
.previousBttn:hover {
    color: rgb(255, 255, 255);
    background-color: #105cd4;
}

.nextBttn {
    color: rgb(255, 255, 255);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
    margin: 0px 2px 0px 2px;
    width: 40px;
    height: 30px;
    border-radius: 20%;
    background-color: #106cfc;
    border: 1px solid #106cfc;
    transition: all 0.3s;
}
.nextBttn:hover {
    color: rgb(255, 255, 255);
    background-color: #105cd4;
}

.paginationBttnsContainer {
    border-color: 2 solid #cc4817;
    list-style: none;
    display: flex;
    position: absolute;
    justify-content: center;
    width: 100%;
    bottom: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 0;
    color: rgb(255, 255, 255);
}

.pageBttn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px 0px 2px;
    width: 30px;
    height: 30px;
    border-radius: 20%;
    background-color: #106cfc;
    border: 1px solid #106cfc;
    transition: all 0.3s;
}
.pageBttn a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
    margin: 0px 2px 0px 2px;
    width: 30px;
    height: 30px;
}
.pageBttn:hover {
background-color: #105cd4;
}

.paginationDisabled {
    color: rgb(255, 255, 255);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: default;
    margin: 0px 2px 0px 2px;
    width: 40px;
    height: 30px;
    border-radius: 20%;
    background-color: #7f7f7f;
    border: 1px solid #7f7f7f;
    transition: all 0.3s;
}
.paginationDisabled:hover {
    color: rgb(255, 255, 255);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: default;
    margin: 0px 2px 0px 2px;
    width: 40px;
    height: 30px;
    border-radius: 20%;
    background-color: #7f7f7f;
    border: 1px solid #7f7f7f;
    transition: all 0.3s;
}

.paginationActive {
    background-color: #063685;
    border: 1px solid #063685;
    color: rgb(255, 255, 255);
}
.paginationActive:hover {
    background-color: #063685;
    border: 1px solid #063685;
    color: rgb(255, 255, 255);
}

.btnAttList {
    position: absolute;
    bottom: 0;
    right: 0;
}