@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

body {
  font-family: "Roboto", sans-serif;
  background-color: #efeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100wh;
  height: 100vh;
  overflow-x: hidden;
}
/* 
@media screen and (max-width: 450px) {
  body {
    height: 100%;
  }
  .imgDelContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
  }
  .boxProfile {
    position: relative;
    border: 1px solid #dfdfdf;
    border-radius: 17px;
    background-color: #fff;
    width: 100%;
    height: 100%;
  }
  .boxProfileFooter .configRodape {
    padding: 10px;
    margin-top: 10px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: black;
    box-shadow: 0px -1px 3px #616161;
    background-color: #e9ecef;
    border-radius: 0 0 17px 17px;
    font-weight: 500;
  }

  .boxProfile span {
    font-size: 14px;
  }
} */