

.profileContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.navBar{
  width: 90vw;
}
.contentContainer {
  border: 1px solid #dfdfdf;
  border-top: 0px;
  border-radius: 0 0 17px 17px;
  margin-top: 0;
  background-color: rgb(255, 255, 255);
  width: 90vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
