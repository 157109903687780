
.boxProfileConfig {
    display: flex;
    flex-direction: column;
    text-align: left;
    border: 1px solid #dfdfdf;
    border-radius: 17px;
    background-color: #fff;
    padding: 20px 20px 20px 20px;
    width: 100%;
    
    
}

.boxProfileConfig  span a {
    text-decoration: none;
    color: black;
    font-weight: 500;
    transition: all 0.3s;
    margin-left: 5px;
}
.boxProfileConfig  span a:hover {
    color: rgb(209, 0, 0);
}


.imgDelContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
}
.imgDel {
    display: flex;
    flex-direction: column;
    margin: 10px;
    box-shadow: 2px 3px 5px #7b7a7a;
    border-radius: 17px;
}

.imgDel img {
    border-radius: 10px 10px 0px 0px;
    width: 90px;
    height: 140px;
}
.imgDel button {
    border-radius: 0px 0px 10px 10px;
    border: 2px solid #dfdfdf;
    transition: all 0.3s;
}
.imgDel button:hover {
    background-color: rgb(187, 0, 0);
    color: white;
    border: 2px solid rgb(187, 0, 0);
    font-weight: 600;
}
.messageCarousel {
    max-width: 400px;
    margin-bottom: 20px;
}
.messageCarouselForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.toast {
    position: absolute;
    color: #0261af;
    background-color: #afd8fa;
    font-weight: 700;
  }
  
.btnCarouselForm {
    max-width: 100px;
}

.boxProfileFooter {
    height: 40%;
}
.emailText{
    font-size: 14px;
}


.configRodape {
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 0;
    align-items: center;
    justify-content: space-around;
    height: 60px;
    display: flex;
    color: black;
    box-shadow: 0px -1px 3px #616161;
    background-color: #e9ecef;
    width: 100%;
    border-radius: 0 0 17px 17px;
    font-weight: 500;
}
  
.configRodape p {
    padding: 0;
    margin: 0;
}
.textPorta{
    font-size: 14px;
}

@media screen and (max-width: 450px){
    
    .boxProfileConfig {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        border: 1px solid #dfdfdf;
        overflow-y: auto;
        border-radius: 17px;
        background-color: #fff;
        padding: 20px 20px 20px 20px;
        overflow-y: auto;
        height: 100%;
        width: 100%;
    }
    .imgDelContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 210px;
        overflow-y: auto;
        margin-bottom: 0px;
        padding: 10px;
    }
    .messageCarousel {
        max-width: 400px;
        margin-bottom: 30px;
        margin-top: 0px;
    }
    .configRodape p {
        font-size: 14px;
        padding: 0;
        margin: 0;
    }


}
