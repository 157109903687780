.paymentInfoText {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}
.btnCancel {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0d6efd;
    color: white;
    width: 65px;
    height: 38px;
    border-radius: 10%;
    cursor: pointer;
    transition: all 0.3s;
  }
.btnCancel:hover {
    background-color: #0b5ed7;
    color: white;
}

@media screen and (max-width: 450px){
    .paymentInfoText {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        margin-left: 10px;
        gap: 10px;
        margin-bottom: 20px;
    }

}