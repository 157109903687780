@import url('https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@300;400;600;700&display=swap');

.boxProfilePayment {
    position: relative;
    border: 1px solid #dfdfdf;
    border-radius: 17px;
    background-color: #fff;
    width: 550px;
    height: 100%;
    padding: 20px 20px 20px 20px;
}
.boxProfilePayment h5 {
    margin: 15px 0 15px 0;
}
.paymentRodape {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    gap: 20px;
    width: 100%;
    padding: 0;
}
  
.paymentRodape span {
    font-weight: 600;
    color: #004ac2;
}

.hrNoMarging {
    margin-top: 0;
    margin-bottom: 16px;
}
.textMoney {
    position: absolute;
    right: 0;
    margin-right: 20px;
    margin-bottom: 10px;
}
.btnPaymentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.textObjetos{
    display: flex;
    flex-direction: column;
    /* font-family: 'Cormorant SC', serif;
    font-weight: 700; */
    gap: 4px;
    
}

@media screen and (max-width: 450px){
    .boxProfilePayment {
        position: relative;
        border: 1px solid #dfdfdf;
        border-radius: 17px;
        background-color: #fff;
        width: 100vw;
        height: 100%;
        padding: 20px 20px 20px 20px;
    }

}