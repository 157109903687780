
.objectsContainter{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.portrait{
    
    height: 400px;
    width: 335px;
    background-size: 120px 170px;
    background-position: center;
    background-repeat: no-repeat;
    transform: translatey(40px);
    left: 70px;
}
.portrait img{
    height: 400px;
}
.boxProfileHomen {
    display: flex;
    flex-direction: column;
    text-align: left;
    border: 1px solid #dfdfdf;
    border-radius: 17px;
    background-image: url("../../../../../images/back2.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 20px 20px 20px 20px;
    width: 100%;
    height: 100%;
}

.vasoContainer{
    position: absolute;
    bottom: 65px;
    transform: translateX(-300px)
    
    
}
.vasoContainer img{
    width: 300px;
}
.beesAnimation{
    display: flex;
    position: absolute;
    width: 400px;
    left: -100px;
    top: -90px;
    
    
}


.vela img{
    position: absolute;
    bottom: 75px;
    transform: translateX(200px);
    width: 100px;
}
.fire{
    position: absolute;
    bottom: 218px;
    transform: translateX(246px);
    width: 150px;
    
}

.btnAnimacoes {
    text-decoration: none;
    position: absolute;
    display: flex;
    right: 0;
    top: 0 ;
    align-items: center;
    justify-content: center;
    background-color: #0d6efd;
    color: white;
    width: 95px;
    padding: 5px;
    border-radius: 10%;
    box-shadow: 1px 1px 5px #000000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.btnAnimacoes:hover {
    background-color: #0b5ed7;
    color: white;
    
}


@media screen and (max-width: 450px){
    .boxProfileHomen {
        background-image: url("../../../../../images/back.jpg");
    }    
        
    .portrait{
        position: absolute;
        height: 220px;
        width: 185px;
        background-size: 65px 95px;
        background-position: center;
        background-repeat: no-repeat;
        transform: translatey(55px);
        left: 70px;
        
        
    }
    .portrait img{
        height: 220px;
        
    }
    
    .vasoContainer{
        position: absolute;
        bottom: 170px;
        transform: translateX(-100px)
    }
    .vasoContainer img{
        width: 150px;
    }
    .beesAnimation{
        display: flex;
        position: absolute;
        width: 150px;
        left: 0px;
        top: -20px;
        
        
    }
    .vela img{
        position: absolute;
        bottom: 175px;
        transform: translateX(105px);
        width: 45px;
    }
    .fire{
        position: absolute;
        bottom: 249px;
        transform: translateX(127px);
        width: 55px;
        
    }

}
